<template>
  <v-theme-provider dark>
    <base-section
      id="social"
      class="accent text-center"
      space="56"
    >
      <base-icon class="mb-8">
             mdi-send
      </base-icon>

      <base-section-heading
        color="transparent"
        title="Social Media"
      >
       {{$ml.get('subscribe_to_channel_motive')}}
      </base-section-heading>

      <a
        class="d-inline-block mb-8"
        href="https://t.me/syrf_official_channel"
        style="text-decoration: none;"
        target="_blank"
      >
        https://t.me/syrf_official_channel
      </a>

      <div class="py-4" />

      <v-btn
        class="font-weight-bold"
        color="white"
        href="https://t.me/syrf_official_channel"
        light
        min-width="168"
        target="_blank"
        x-large
      >
         {{$ml.get('subscribe_to_channel')}}

        <v-icon right>
          mdi-open-in-new
        </v-icon>
      </v-btn>
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionNews',

    provide: {
      heading: { align: 'center' },
    },
  }
</script>
